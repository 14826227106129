import gql from 'graphql-tag';
const DistrictCtx = gql`
  fragment DistrictCtx on District {
    id
    name
    isMic
    state {
      abbreviation
      id
      name
    }
    programGroup {
      id
      name
    }
  }
`;
const SchoolCtx = gql`
  ${DistrictCtx}
  fragment SchoolCtx on School {
    id
    name
    district { ...DistrictCtx }
  }
`;
const ClassCtx = gql`
  ${SchoolCtx}
  fragment ClassCtx on Class {
    id
    name
    type
    avatarImageUrl
    avatarBorderColor
    avatarBackgroundColor
    current
    phantomClass
    school { ...SchoolCtx }
    semester
    grade
    endDate
    startDate
  }
`;
export const UI_GET_USER_CONTEXT = gql`
  ${DistrictCtx}
  ${SchoolCtx}
  ${ClassCtx}
  query UI_GET_USER_CONTEXT($userId: ID!) {
    userById(id: $userId) {
      id
      first_name
      last_name
      name
      district { ...DistrictCtx }
      schools { ...SchoolCtx }
      classes { ...ClassCtx }
      enrollments {
        from
        to
        class { ...ClassCtx }
      }
      parents {
        id
        name
      }
      appsWithUsage
      earliestInteraction
      latestInteraction
      creationDate
      type
      email
      studentID
    }
  }
`;
export const UI_GET_CLASS_CONTEXT = gql`
  ${ClassCtx}
  query UI_GET_CLASS_CONTEXT($classId: ID!) {
    classById(id: $classId) {
      ...ClassCtx
      grade
      contentGradeLevel
      appsWithUsage
      teachers {
        id
        name
      }
      students {
        id
        name
      }
    }
  }
`;
export const UI_GET_SCHOOL_CONTEXT = gql`
  ${SchoolCtx}
  query UI_GET_SCHOOL_CONTEXT($schoolId: ID!) {
    schoolById(id: $schoolId) {
      ...SchoolCtx
      type
      semestersV2 {
        earliestDate
        latestDate
        name
        order
      }
    }
  }
`;
export const UI_GET_DISTRICT_CONTEXT = gql`
  ${DistrictCtx}
  query UI_GET_DISTRICT_CONTEXT($districtId: ID!) {
    districtById(id: $districtId) {
      ...DistrictCtx
      startDate
      semestersV2 {
        earliestDate
        latestDate
        name
        order
      }
    }
  }
`;
export const UI_GET_PROGRAMGROUP_CONTEXT = gql`
  query UI_GET_PROGRAMGROUP_CONTEXT($programGroupId: ID!) {
    programGroupById(id: $programGroupId) {
      id
      name
      hasMic
      startDate
      semestersV2 {
        earliestDate
        latestDate
        name
        order
      }
    }
  }
`;