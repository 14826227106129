import ClassAvatar from '../../../components/images/ClassAvatar';
const breadcrumbFor = ({
  id,
  name,
  __typename: type,
  ...rest
}) => ({
  id: id,
  name: name,
  type: type === 'ProgramGroup' ? 'Program' : type,
  icon: type === 'Class' ? <ClassAvatar of={rest} size="1.5em" /> : null
});
export default breadcrumbFor;