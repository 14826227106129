import { useState } from 'react';
import * as Sentry from '@sentry/nextjs';
function ErrorTest() {
  const [message, setMessage] = useState('');
  const triggerError = () => {
    try {
      throw new Error('Test error for Sentry');
    } catch (error) {
      Sentry.captureException(error);
      setMessage('Error triggered and sent to Sentry');
    }
  };
  const triggerPromiseRejection = () => {
    Promise.reject(new Error('Test promise rejection')).catch(error => {
      Sentry.captureException(error);
      setMessage('Promise rejection triggered and sent to Sentry');
    });
  };
  const triggerConsoleError = () => {
    console.error('Test console error');
    setMessage('Console error logged');
  };
  return <div data-sentry-component="ErrorTest" data-sentry-source-file="error-test.jsx">
      <h1>Error Test Page</h1>
      <button onClick={triggerError}>Trigger Error</button>
      <button onClick={triggerPromiseRejection}>Trigger Promise Rejection</button>
      <button onClick={triggerConsoleError}>Trigger Console Error</button>
      <p>{message}</p>
    </div>;
}
export default ErrorTest;